"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsLeistungenColumnsDefs = void 0;
const editSettingsStore_1 = require("@/state/settings/editSettingsStore");
function settingsLeistungenColumnsDefs(key) {
    return [
        {
            headerName: 'Nummer',
            field: 'nummer',
            sortable: true,
            sort: 'asc',
            width: 120,
        },
        {
            headerName: 'Kürzel',
            field: 'kuerzel',
            width: 80,
            sortable: true,
        },
        {
            headerName: 'Text',
            field: 'text',
            sortable: true,
            resizable: true,
            flex: 2,
        },
        {
            headerName: 'Art',
            field: 'typ',
            width: 80,
            sortable: true,
            resizable: true,
        },
        {
            headerName: '',
            field: 'nummer',
            cellRenderer: 'DeleteButtonCell',
            width: 50,
            cellStyle: { justifyContent: 'end' },
            cellRendererParams: {
                context: {
                    clicked(field) {
                        editSettingsStore_1.editSettingsStore.commit.deleteLeistung({
                            key: key,
                            items: [field.column.toLowerCase()],
                        });
                    },
                },
            },
        },
        {
            headerName: '',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            width: 40,
        },
    ];
}
exports.settingsLeistungenColumnsDefs = settingsLeistungenColumnsDefs;
